import React from "react";
import PropTypes from "prop-types";
import MediaImagePlaceholder from "../MediaImagePlaceholder";
import Image from "../Image";
import { inferMediaType, getThumbnail } from "../../lib/helpers";
import { mediaType } from "../../types";

function MediaThumbnail({ media, height, width }) {
  const type = inferMediaType(media);
  const thumbnail = getThumbnail(media);

  return thumbnail && thumbnail.url ? (
    <Image
      style={{ borderRadius: "4px" }}
      src={thumbnail.url}
      alt={thumbnail.alt}
      height={height}
      width={width}
    />
  ) : (
    <MediaImagePlaceholder
      style={{ borderRadius: "4px" }}
      height={height}
      type={type}
      width={width}
    />
  );
}

MediaThumbnail.defaultProps = {
  height: 300,
  width: 600,
};

MediaThumbnail.propTypes = {
  media: mediaType.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default MediaThumbnail;
