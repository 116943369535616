import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Link as GatsbyLink } from "gatsby";
import Link from "@material-ui/core/Link";
import { albumType } from "../../types";
import MediaSelect from "../MediaSelect";
import MediaViewer from "../MediaViewer";
import NoContent from "../NoContent";

function AlbumMedia({ album, className }) {
  if (!album) {
    return null;
  }

  if (!album.media || album.media.length === 0) {
    return <NoContent text="This Album Has No Media" />;
  }

  const { media } = album;
  const [selectedMedia, setSelectedMedia] = useState(media[0]);

  const handleSelect = (id) => {
    const selected = media.find((m) => m.id === id);
    setSelectedMedia(selected);
  };

  return (
    <div className={className}>
      <MediaViewer media={selectedMedia} />
      <Typography variant="h4">
        <Link
          component={GatsbyLink}
          to={`/media/${selectedMedia.slug.current}`}
        >
          {selectedMedia.title}
        </Link>
      </Typography>
      <MediaSelect media={media} onSelect={handleSelect} />
    </div>
  );
}

AlbumMedia.defaultProps = {
  album: null,
  className: null,
};

AlbumMedia.propTypes = {
  album: albumType,
  className: PropTypes.string,
};

export default AlbumMedia;
