import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import { mediaType } from "../../types";
import MediaThumbnail from "../MediaThumbnail";
import useDownloadMedia from "../../hooks/useDownloadMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  list: {
    width: "100%",
    listStyle: "none",
    padding: theme.spacing(4, 0),
    margin: 0,
    overflowX: "auto",
    whiteSpace: "nowrap",
  },
  item: {
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    display: "inline-block",
    transition: `box-shadow ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    marginRight: theme.spacing(4),
    "&:first-child": {
      marginLeft: theme.spacing(4),
    },
  },
  itemSelected: {
    boxShadow: theme.shadows[16],
  },
  title: {
    position: "absolute",
    borderTopRightRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    background: fade(theme.palette.background.dark, 0.8),
    left: 0,
    bottom: 0,
  },
  download: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
}));

function MediaSelect({ media, onSelect }) {
  if (media.length === 0) {
    return null;
  }

  const classes = useStyles();
  const [selected, setSelected] = useState(media[0].id);

  const handleClick = (id) => {
    setSelected(id);
    onSelect(id);
  };

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {media.map((m) => {
          const { isDownloadable, url } = useDownloadMedia(m);
          return (
            <li
              key={m.id}
              className={`${classes.item} ${
                m.id === selected ? classes.itemSelected : null
              }`}
              title={m.title}
            >
              <ButtonBase
                component="div"
                onClick={() => {
                  handleClick(m.id);
                }}
              >
                <MediaThumbnail media={m} width={300} height={150} />
                <Typography className={classes.title}>{m.title}</Typography>
                {isDownloadable && (
                  <IconButton
                    component="a"
                    title="download media"
                    variant="extended"
                    className={classes.download}
                    size="small"
                    color="primary"
                    aria-label="download"
                    href={url}
                    download
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
              </ButtonBase>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

MediaSelect.defaultProps = {
  media: [],
  onSelect: () => {},
};

MediaSelect.propTypes = {
  media: PropTypes.arrayOf(mediaType),
  onSelect: PropTypes.func,
};

export default MediaSelect;
